<!--
 * @Author: Leif
 * @Date: 2021-12-13 09:37:52
 * @LastEditTime: 2022-08-22 21:11:20
 * @LastEditors: ch3nh2
 * @Description: 通话中挂电话遮罩组件
-->
<template>
  <transition name="InCallCancel" v-show="visible">
    <div class="InCallCancel">
      <p class="nodata-text">正在通话中……</p>
      <div class="mobile-phone-item-pass" @click="onCancel"></div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "InCallCancel",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {

    const onCancel = () => {
      context.emit("onCancel");
    };

    return {
      onCancel,
    };
  },
};
</script>

<style lang="scss">
.InCallCancel {
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(6, 6, 6, 0.8);

  .mobile-phone-item-pass {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-top: 40px;
    background-image: url("../assets/image/components/MobilePhone/pass-icon.png");
    background-size: 100%;
    background-repeat: no-repeat;

    &:active {
      opacity: 0.5;
    }
  }
}
</style>
