<!--
 * @Author: ch3nh2
 * @Date: 2022-10-11 11:57:21
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-03-06 19:33:43
 * @FilePath: \zhy_dataview_fe\src\components\BroadcastCall.vue
 * @Description: 广播（程控版）
-->
<template>
  <div class="broadcast">
  <template v-if="flag">
      <div class="broadcast-flag"></div>
    </template>
    <div class="broadcast-title">
      <!-- <div class="broadcast-title-select-item" v-if="!broadcastStatus">
            <el-select placeholder="选择广播" size="mini" v-model="broadcastIndex">
              <el-option v-for="(item, index) in broadcastArea" :key="index" :label="item.areaName" :value="index" />
            </el-select>
          </div> -->
    </div>
    <div class="broadcast-content">
      <div class="broadcast-content-menu-default">
        <div class="broadcast-content-on" @click="broadcastclick">
          <div :class="{
            'broadcast-content-tel-icon': true,
            'broadcast-content-tel-on-icon': true,
            'broadcast-content-tel-close-icon': broadcastStatus,
          }"></div>
          <div class="broadcast-content-on-text">
            <!-- {{`${broadcastArea[broadcastIndex].areaName} ${broadcastStatus ? '[通话中]' : '[空闲中]'}`}} -->
            {{ broadcastStatus ? "[通话中]" : "[空闲中]" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Broadcast",
  data() {
    return {
      flag: false,
      broadcastArea: [
        {
          areaName: "CEPF&WHPE",
          areaCode: "73#33",
          areaNumber: "30",
        },
        {
          areaName: "JZ25-1SCEP",
          areaCode: "70#44",
          areaNumber: "30",
        },
        {
          areaName: "JZ25-1CEP",
          areaCode: "75#55",
          areaNumber: "30",
        },
        {
          areaName: "JX1-1CEPA",
          areaCode: "78#44",
          areaNumber: "30",
        },
        {
          areaName: "WHPC",
          areaCode: "72#33",
          areaNumber: "30",
        },
        {
          areaName: "WHPD",
          areaCode: "77#33",
          areaNumber: "30",
        },
      ],
      broadcastIndex: 0,
      broadcastStatus: false,
    };
  },

  methods: {
    sleep(delay) {
      var start = new Date().getTime();
      while (new Date().getTime() - start < delay) {
        continue;
      }
    },
    // 点击广播
    broadcastclick() {
      !this.broadcastStatus ? this.turnOnBroadcast() : this.turnOffBroadcast();
    },
    // 打开广播
    turnOnBroadcast() {
      const areaName = this.broadcastArea[this.broadcastIndex].areaName;
      const areaNumber = this.broadcastArea[this.broadcastIndex].areaNumber;
      this.$JX.callOutToBroadcast(areaName, areaNumber, false);
      this.$store.dispatch("setSingleCallName", "broadcast");

      // 模拟
      // this.onMessageQTEvent({
      //   detail: {
      //     type: "singleCallStatus",
      //     callInfo: { status: "talking", peerNumber: "30" },
      //   },
      // });
    },
    // 关闭广播
    turnOffBroadcast() {
      const areaNumber = this.broadcastArea[this.broadcastIndex].areaNumber;
      this.$JX.releaseToBroadcast(areaNumber);

      // 模拟
      // this.onMessageQTEvent({
      //   detail: {
      //     type: "singleCallStatus",
      //     callInfo: { status: "idle", peerNumber: "30" },
      //   },
      // });
    },
    onMessageQTEvent({ detail }) {
      const { type, callInfo } = detail;
      const name = this.$store.state.singleCallName;
      const broadcast = this.broadcastArea[this.broadcastIndex];
      const areaCode = broadcast.areaCode.split("");
      if (name === "broadcast" && type && type == "singleCallStatus") {
        const msg = { talking: "呼叫", idle: "挂断" };
        this.$utils.showLoading({
          text: `正在${msg[callInfo.status]}广播，请稍后...`,
        });
        this.$utils.timeOut(300).then(() => {
          if (callInfo.status == "talking" && !this.broadcastStatus) {
            for (var i = 0; i < 3; i++) {
              this.$JX.callSecondDial(callInfo.peerNumber, areaCode[i]);
              this.sleep(300);
            }
            this.sleep(5 * 1000);
            for (var i = 3; i < areaCode.length; i++) {
              this.$JX.callSecondDial(callInfo.peerNumber, areaCode[i]);
              this.sleep(300);
            }
            this.broadcastStatus = true;
            this.$utils.hideLoading();
          }
          if (callInfo.status == "idle" && this.broadcastStatus) {
            this.$store.dispatch("setSingleCallName", "");
            this.broadcastStatus = false;
            this.$utils.hideLoading();
          }
        });
      }
    },
  },
  created() {
    const showflag = localStorage.getItem("ACCESS_PERMISSIONS");
    if (showflag) {
      const split = showflag.split(",");
      if (split.length >= 1 && split.findIndex((item) => item == "a004") >= 1) {
        this.flag = false;
      }
    }
    window.addEventListener("onMessageQT", this.onMessageQTEvent);
  },
  beforeUnmount() {
    window.removeEventListener("onMessageQT", this.onMessageQTEvent);
  },
};
</script>

<style lang="scss">
.broadcast {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  left: 20px;
  width: 420px;
  height: 209px;
  background-image: url("../assets/image/components/Broadcast/background.png");
  background-size: 100%;
  background-repeat: no-repeat;

  .broadcast-flag {
    width: 100%;
    height: 93%;
    bottom: 0px;
    position: absolute;
    background-color: #333;
    filter: Alpha(Opacity=60);
    opacity: 0.6;
    z-index: 9999;
  }

  .broadcast-title {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .broadcast-content {
    position: absolute;
    top: 40px;
    width: 100%;
    height: 169px;
    overflow: hidden;

    .broadcast-content-menu-datetime {
      margin: 8px;
      background: rgba(86, 96, 121, 0.7);
      border-radius: 4px;

      .el-input {
        width: 100%;
        display: block !important;
      }
    }

    .broadcast-content-menu-done {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin: 8px;
      height: 40px;
      background: #08b1a0;
      border-radius: 4px;

      &:active {
        opacity: 0.5;
      }
    }

    .broadcast-content-menu-default {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .broadcast-content-on {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        width: 60%;
        height: 100%;

        &:active {
          opacity: 0.5;
        }

        .broadcast-content-tel-icon {
          width: 90px;
          height: 90px;
          margin-bottom: 15px;
          background-size: 100%;
          background-repeat: no-repeat;
        }

        .broadcast-content-tel-on-icon {
          background-image: url("../assets/image/components/Broadcast/on-icon.png");
        }

        .broadcast-content-tel-close-icon {
          background-image: url("../assets/image/components/Broadcast/close-icon.png");
        }

        .broadcast-content-on-text {
          color: #ffffff;
        }
      }

      .broadcast-content-on-disable {
        cursor: no-drop;
        opacity: 0.5;

        &:active {
          opacity: 0.5;
        }
      }

      .broadcast-content-off {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40%;
        height: 100%;

        &:active {
          opacity: 0.5;
        }

        .broadcast-content-off-icon {
          width: 50px;
          height: 50px;
          background-image: url("../assets/image/components/Broadcast/off-icon.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      .broadcast-content-off-disable {
        cursor: no-drop;
        opacity: 0.5;

        &:active {
          opacity: 0.5;
        }
      }
    }

    .broadcast-content-menu-audio {
      height: 100%;

      .broadcast-content-menu-upload {
        margin: 8px;
        height: 60px;

        .broadcast-content-menu-upload-btn {
          display: flex;
          align-items: center;
          padding: 14px;
          border-radius: 4px;
          background-image: linear-gradient(to right, #2a2e3a, #181825);

          &:active {
            opacity: 0.5;
          }

          .broadcast-content-menu-upload-icon {
            width: 28px;
            height: 28px;
            margin-right: 15px;
            background-image: url("../assets/image/components/Broadcast/audio-icon.png");
            background-size: 100%;
            background-repeat: no-repeat;
          }

          .broadcast-content-menu-upload-text {
            color: #ffffff;
          }
        }
      }
    }

    .broadcast-content-menu-text {
      height: 100%;

      .broadcast-content-menu-input {
        margin: 8px;
        border-radius: 4px;
        background-image: linear-gradient(to right, #2a2e3a, #181825);
      }
    }
  }
}

.el-picker-panel__link-btn {
  color: #181825;
}

.el-textarea__inner {
  color: #ffffff;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sendtime .el-button--text {
  display: none !important;
}

.deletebtn {
  width: 70px;
  height: 30px;
  background: red;
  color: #ffffff;
  border-radius: 5px;
  line-height: 30px;
  float: right;
  margin-left: 20px;
}
</style>
