<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:25:41
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-20 20:39:04
 * @FilePath: /zhy_dataview_fe/src/components/Videos.vue
 * @Description: 视频监控
-->
<template>
  <div class="videos general_box_area arrow_card" :style="`top: ${top}px`">
    <b class="b bottom_left"></b><b class="b bottom_right"></b>
    <div class="videos-title general_box_header">
      <b class="b top_left"></b><b class="b top_right"></b>
      <div class="general_box_left" @click="goPage">
        <template v-for="(i, index) in $store.state.monitordata.machineList">
          <template v-if="index == 0">
            {{ i.machineTypeName }}
          </template>
        </template>
      </div>
    </div>

    <div class="videos-content" :style="`height: ${height}px`">
      <template v-for="(i, index) in $store.state.monitordata.machineList">
        <template v-if="index == 0">
          <template v-for="(a, s) in i.configList">
            <div class="board_videos_cover">
              <VideoPlayer v-if="$config.dev||$config.sit" type="img" :source="a.flvUrl" />
              <VideoPlayer v-else :source="a.flvUrl" />
            </div>
            <div class="board_videos_text">{{ a.name }}</div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./InsidePages/VideoPlayer";
export default {
  name: "Videos",
  data() {
    return {

    };
  },
  computed: {},
  components: { VideoPlayer },
  props: {
    top: {
      default: () => 280
    },
    height: {
      default: () => 720
    },
  },
  methods: {
    goPage() {
      this.$router.push({
        path: "/monitoring/screen",
        query: { hideNavigation: true },
      });
    },
  },
  created() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.videos {
  position: absolute;
  z-index: 99;
  right: 20px;
  width: 420px;

  .videos-content {
    padding: 10px;
    overflow-y: scroll;

    .board_videos_cover {
      height: 260px;
    }

    .board_videos_text {
      padding: 10px 0;
      color: #ffffff
    }
  }
}
</style>
