<!--
 * @Author: PC
 * @Date: 2022-01-07 16:59:42
 * @LastEditTime: 2022-10-20 20:27:16
 * @Description: 请您输入文件描述
-->
<template>
  <div class="home">
    <HomeHead :isHome="true" />
    <transition name="el-zoom-in-center">
      <EventLog class="transition-box" v-show="EventLogTran" />
    </transition>
    <transition name="el-zoom-in-center">
      <MobilePhone class="transition-box" v-show="MobilePhoneTran" :mobilephone="permissionsflag.MobilePhone" />
    </transition>
    <transition name="el-zoom-in-center">
      <InterPhone class="transition-box" v-show="InterPhoneTran" :interphone="permissionsflag.InterPhone" />
    </transition>
    <transition name="el-zoom-in-center">
      <BroadcastCall class="transition-box" v-show="BroadcastTran" />
    </transition>
    <transition name="el-zoom-in-center">
      <Meteorology class="transition-box" @meteorologyChart="meteoroLogChange" v-show="MeteorologyTran" />
    </transition>
    <!-- <transition name="el-zoom-in-center">
      <MonitoringVideos class="transition-box" v-show="MonitoringVideos" />
    </transition> -->
    <!-- <transition name="el-zoom-in-center">
      <BoardVideos class="transition-box" v-show="BoardVideos" />
    </transition> -->
    <transition name="el-zoom-in-center">
      <Videos :top="videosTop" :height="videosHeight" class="transition-box" v-show="VideosTran" />
    </transition>
    <transition name="el-zoom-in-center">
      <BottomMenu class="transition-box" v-show="BottomMenuTran" />
    </transition>
  </div>
</template>

<script>
import HomeHead from "components/HomeHead";
import EventLog from "components/EventLog";
import MobilePhone from "components/MobilePhone";
import InterPhone from "components/InterPhone";
import BroadcastCall from "components/BroadcastCall";
import Meteorology from "components/Meteorology";
import Videos from "components/Videos";
import MonitoringVideos from "components/MonitoringVideos";
import BoardVideos from "components/BoardVideos";
import BottomMenu from "components/BottomMenu";
export default {
  name: "Home",
  data() {
    return {
      websock: null,
      wsHeartflag: false,
      EventLogTran: false,
      MobilePhoneTran: false,
      InterPhoneTran: false,
      BroadcastTran: false,
      MeteorologyTran: false,
      MonitoringVideos: false,
      BoardVideos: false,
      VideosTran: false,
      BottomMenuTran: false,
      transitionArr: [
        { name: "EventLogTran" },
        { name: "MobilePhoneTran" },
        { name: "InterPhoneTran" },
        { name: "BroadcastTran" },
        { name: "MeteorologyTran" },
        { name: "MonitoringVideos" },
        { name: "BoardVideos" },
        { name: "VideosTran" },
        { name: "BottomMenuTran" },
      ],
      videosTop: 280,
      videosHeight: 720,
      permissionsflag: {
        MobilePhone: true,
        InterPhone: true,
        Broadcast: true,
      },
    };
  },
  computed: {},
  components: {
    HomeHead,
    EventLog,
    MobilePhone,
    InterPhone,
    BroadcastCall,
    Meteorology,
    BoardVideos,
    MonitoringVideos,
    Videos,
    BottomMenu,
  },
  props: {},
  methods: {
    meteoroLogChange(status) {
      if (status) {
        this.videosTop = 510
        this.videosHeight = 490
      } else {
        this.videosTop = 280
        this.videosHeight = 720
      }
    },
    initShow() {
      for (let i = 0; i < this.transitionArr.length; i++) {
        setTimeout(() => {
          this[this.transitionArr[i].name] = true;
        }, 200 * i);
      }
    },
    initHide() {
      for (let i = 0; i < this.transitionArr.length; i++) {
        setTimeout(() => {
          this[this.transitionArr[i].name] = false;
        }, 200 * i);
      }
    },
  },
  mounted() {
    this.initShow();
  },
  created() {
    this.$api.userInfo().then((res) => {
      const { data, code } = res;
      if (code == "B200") {
        this.$store.commit("SET_TOKEN", data);
        localStorage.setItem("USER_INFO", JSON.stringify(data));
        localStorage.setItem("ACCESS_PERMISSIONS", res.data.permissions);
      }
    });

    var permissionsdata = localStorage.getItem("ACCESS_PERMISSIONS");
    if (permissionsdata) {
      if (permissionsdata.split(",").length >= 1) {
        if (
          permissionsdata.split(",").findIndex((item) => item == "a002") >= 1
        ) {
          this.permissionsflag.MobilePhone = false;
        }
        if (
          permissionsdata.split(",").findIndex((item) => item == "a003") >= 1
        ) {
          this.permissionsflag.InterPhone = false;
        }
        if (
          permissionsdata.split(",").findIndex((item) => item == "a004") >= 1
        ) {
          this.permissionsflag.Broadcast = false;
        }
      }
    }
  },
};
</script>

<style lang="scss">
.home {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
