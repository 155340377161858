<!--
 * @Author: Zion0707
 * @Date: 2021-10-25 18:16:54
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-11-16 16:47:22
 * @FilePath: /zhy_dataview_fe/src/components/EventLogDetail.vue
 * @Description: 事件日志
-->
<template>
  <div class="event-log_detail">
    <div class="event-log_detail-in">
      <div :class="['title', eventTypeMap[detailData.eventType]]">
        {{ detailData.title || "无" }}
      </div>
      <div class="time-address">
        <div class="time">时间：{{ formatMsgTime(detailData.eventTime) || "-" }}</div>
        <div class="address">地点：{{ detailData.eventLocation || "无" }}</div>
      </div>
      <div class="content">
        <div class="name">内容：</div>
        <div class="con">{{ detailData.content || "无" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  data() {
    return {
      eventTypeMap: {
        ALARM: "red",
        BROADCAST: "yellow",
        LINKAGE: "green",
      },
      detailData: {},
      formatMsgTime: utils.formatMsgTime,
    };
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    // 获取日志详情
    getEventMessageReadApi() {
      this.$api.getEventMessageRead({ id: this.id }).then((res) => {
        const { data } = res;
        this.detailData = data;
      });
    },
  },
  mounted() {
    this.getEventMessageReadApi();
  },
  unmounted() {},
};
</script>

<style lang="scss">
.event-log_detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  overflow: hidden;
  .event-log_detail-in {
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .title {
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 30px;
    &.red {
      color: #ab1006;
    }
    &.yellow {
      color: #e49729;
    }
    &.green {
      color: #33d3c9;
    }
  }
  .time-address {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    .time {
      width: 210px;
    }
    .address {
      flex: 1;
      text-align: right;
    }
  }
  .content {
    font-size: 14px;
    .name {
      margin-bottom: 10px;
    }
    .con {
      text-indent: 3em;
    }
  }
}
</style>
