<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:22:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-08-24 15:40:00
 * @FilePath: /zhy_dataview_fe/src/components/MobilePhone.vue
 * @Description: 电话
-->
<template>
  <div class="mobile-phone-wrap">
    <el-drawer v-model="drawer" title="通讯录列表" direction="ltr" size="25%">
      <div class="drawer-wrap">
        <div class="search-input-wrap">
          <el-input v-model="filterText" class="search-input" placeholder="请输入关键字" clearable>
            <template #prefix>
              <el-icon class="el-input__icon">
                <search />
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="mobile-phone-user-list" v-if="allUser && allUser.length > 0" v-infinite-scroll="getAllContact"
          :infinite-scroll-disabled="loading || noMore">
          <div class="mobile-phone-user-item" v-for="(item, index) in allUser" :key="index">
            <div class="mobile-phone-user-info">
              <div class="mobile-phone-user-avatar"></div>
              <div class="mobile-phone-user-data">
                <div class="mobile-phone-user-name-wrap">
                  <div class="mobile-phone-user-name">
                    {{ item.name }}
                  </div>
                  <!-- <div class="mobile-phone-user-dep">
                    {{ item.orgName || "暂无" }}
                  </div> -->
                </div>
                <div class="mobile-phone-user-number-wrap">
                  <div class="mobile-phone-user-number">
                    {{ item.account }}
                  </div>
                  <!-- <div class="mobile-phone-user-number-icon" @click="onItemNumber(item, index)">
                    <el-icon class="el-icon--right">
                      <arrow-right-bold v-if="!item.numberIcon" />
                      <arrow-down-bold v-else />
                    </el-icon>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="mobile-phone-user-menu">
              <div :class="
                item.isExtensionQuick
                  ? 'mobile-phone-user-add mobile-phone-user-add-disable'
                  : 'mobile-phone-user-add'
              " @click="onAddFav(item, index)">
                <el-icon color="#ffffff">
                  <plus></plus>
                </el-icon>
              </div>
              <div class="mobile-phone-user-call" @click="onCall(item, index)"></div>
            </div>
          </div>
        </div>
        <p class="list-tips" v-else> {{ filterText ? "暂无搜索结果" : "当前暂无联系人" }}</p>
      </div>
    </el-drawer>
    <div class="mobile-phone">
      <template v-if="showflag">
        <div class="no-permission"></div>
      </template>
      <div class="mobile-phone-content">
        <InCallCancel @onCancel="onCallCancel" v-model:visible="$store.state.telePhoneStatus" />
        <div class="mobile-phone-call-icon" @click="onInputCall"></div>
        <div class="mobile-phone-connects-icon" @click="onMore"></div>
        <div class="mobile-phone-input-wrap">
          <el-input v-model="phoneNumber" maxlength="11" class="mobile-phone-input" placeholder="请输入电话号码" clearable />
        </div>
        <div ref="scrollWrap" class="mobile-phone-item-wrap">
          <div class="mobile-phone-item-content" v-if="favUser.length > 0" v-for="(item, index) in favUser"
            :key="index">
            <div class="mobile-phone-item">
              <div class="mobile-phone-item-active">
                <div class="mobile-phone-item-active-phone" @click="onCall(item, index)"></div>
                <div class="mobile-phone-item-active-del" @click="onDelFav(item, index)"></div>
                <!-- <div class="mobile-phone-item-active-setting" @click="onItemNumber(item, index)"></div> -->
              </div>
              <div class="mobile-phone-item-icon"></div>
              <span class="mobile-phone-item-text">{{ item.name }}</span>
            </div>
          </div>
          <p v-else class="nodata-text">当前暂无快捷联系人</p>
        </div>
      </div>
    </div>
    <!-- <el-dialog center v-model="dialogVisible" top="30vh" width="250px" custom-class="dialog" @close="onCloseDialog">
      <el-form :model="setForm">
        <el-form-item>
          <el-radio-group v-model="setForm.radio" @change="onRadio">
            <el-radio label="phone">
              电话号：{{ setItem.account || "暂无" }}
            </el-radio>
            <el-radio label="mobile">
              手机号：{{ setItem.mobile || "暂无" }}
            </el-radio>
            <el-radio disabled label="all"> 群呼 </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #title>
        <div class="dialog-title">
          <el-icon>
            <avatar />
          </el-icon>
          <span class="dialog-title-name">{{ setItem.name }}</span>
        </div>
      </template>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  Search,
  ArrowRightBold,
  ArrowDownBold,
  Plus,
  Avatar,
} from "@element-plus/icons";
import InCallCancel from "./InCallCancel.vue";

export default {
  name: "MobilePhone",
  data() {
    return {
      page: 1,
      loading: false,
      noMore: false,
      favUser: [],
      allUser: [],
      callName: "包工头",
      callIng: false,
      drawer: false,
      filterText: "",
      phoneNumber: "",
      dialogVisible: false,
      itemIndex: null,
      setItem: {},
      setForm: {
        radio: "phone",
      },
      showflag: this.mobilephone,
    };
  },
  watch: {
    filterText() {
      this.initGet(true);
    },
  },
  computed: {},
  components: {
    Search,
    Plus,
    Avatar,
    ArrowRightBold,
    ArrowDownBold,
    InCallCancel,
  },
  props: ["mobilephone"],
  methods: {
    onCall(item, index) {
      if (this.drawer) this.drawer = false;
      this.$store.dispatch('setSingleCallName', 'mobile')
      this.$JX.callOut(item.name, item.account)
    },
    onInputCall() {
      if (this.phoneNumber == "") {
        this.$utils.errorAlert("请先输入对应的电话号码");
        return false;
      }
      this.$store.dispatch('setSingleCallName', 'mobile')
      this.$JX.callOut('自定义号码', this.phoneNumber)
    },
    onCallCancel() {
      this.$JX.releaseToBroadcast(this.$store.state.singleCallNumber)
    },
    async onDelFav(item, index) {
      const data = await this.$api.delFavContacts([{ contactId: item.id, quickType: 1 }])
      if (data.code == 'B200') {
        this.getFavContacts()
        this.$message({ message: "删除快捷联系人成功", type: "success" });
      }
    },
    async onAddFav(item, index) {
      if (item.isExtensionQuick) return;
      const data = await this.$api.addFavContacts([{
        contactId: item.id,
        quickType: 1,
      }]);
      if (data.code == 'B200') {
        this.initGet(true);
        this.$message({
          message: "添加快捷联系人成功",
          type: "success",
        });
      }
    },
    onMore() {
      this.drawer = true;
      this.initGet(true);
    },
    async getFavContacts() {
      const favRes = await this.$api.getFavContacts({ quickType: 1 });
      const { code, data } = favRes;
      if (code == 'B200') {
        data.jiaXunContacts.map((item) => {
          item.account = item.numberInfo.length > 0 ? item.numberInfo[0].userNumber : item.account
        })
        this.favUser = data.jiaXunContacts;
      }
    },
    async getAllContact(onRefresh) {
      if (onRefresh) {
        this.page = 1;
        this.allUser = [];
      }
      this.loading = true
      const allRes = await this.$api.getAllContact({
        numberType: 1,
        keyword: this.filterText,
        page: this.page,
        pageSize: 10
      });
      const { code, data } = allRes;
      if (code == 'B200') {
        data.jiaXunContacts.map((item) => {
          item.account = item.numberInfo.length > 0 ? item.numberInfo[0].userNumber : item.account
        })
        this.loading = false;
        this.noMore = data.jiaXunContacts.length == 0;
        this.page = data.jiaXunContacts.length > 0 ? this.page + 1 : this.page
        this.allUser = [...this.allUser, ...data.jiaXunContacts]
      };
    },
    initGet(onRefresh) {
      this.getFavContacts()
      this.getAllContact(onRefresh)
    },
    onMessageQTEvent({ detail }) {
      const { type, callInfo } = detail;
      const name = this.$store.state.singleCallName;
      if (name == 'mobile' && type == 'singleCallStatus') {
        this.$store.dispatch('setSingleCallNumber', callInfo.peerNumber);
        if (callInfo.status == 'talking' || callInfo.status == 'ringBack') {
          this.$store.dispatch('setTelPhoneState', true);
        }
        if (callInfo.status == 'idle') {
          this.$store.dispatch('setSingleCallName', '');
          this.$store.dispatch('setTelPhoneState', false);
        }
      }
    }
    // onItemNumber(item, index) {
    //   this.itemIndex = index;
    //   this.setItem = item;
    //   this.allUser[index].numberIcon = !this.allUser[index].numberIcon;
    //   this.dialogVisible = true;
    // },
    // onRadio(val) {
    //   this.$api
    //     .setDefaultCall({
    //       contactId: this.setItem.id,
    //       type: val,
    //     })
    //     .then((res) => {
    //       this.initGet(true);
    //       this.$message({
    //         message: "设置默认联系方式成功",
    //         type: "success",
    //       });
    //     });
    // },
    // onCloseDialog() {
    //   const index = this.itemIndex;
    //   this.allUser[index].numberIcon = !this.allUser[index].numberIcon;
    // },
  },
  created() {
    this.initGet(true);
    window.addEventListener('onMessageQT', this.onMessageQTEvent);
  },
  mounted() {
    this.bodyWheel = this.$utils.WheelDirective(this.$refs.scrollWrap);
    this.bodyWheel.add()
  },
  beforeUnmount() {
    this.bodyWheel.remove()
    window.removeEventListener('onMessageQT', this.onMessageQTEvent);
  },
};
</script>

<style lang="scss">
.mobile-phone-wrap {
  .mobile-phone {
    position: absolute;
    z-index: 99;
    top: 407px;
    left: 20px;
    width: 420px;
    height: 198px;
    background-image: url("../assets/image/components/MobilePhone/background.png");
    background-size: 100%;
    background-repeat: no-repeat;

    .no-permission {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #333;
      filter: Alpha(Opacity=60);
      opacity: 0.6;
      z-index: 9999;
    }

    .mobile-phone-content {
      position: absolute;
      top: 40px;
      width: 100%;
      height: 157px;
      overflow: hidden;

      .mobile-phone-call-icon {
        position: absolute;
        top: 15px;
        right: 22px;
        width: 40px;
        height: 40px;
        background-image: url("../assets/image/components/MobilePhone/call-icon.png");
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;

        &:active {
          opacity: 0.5;
        }
      }

      .mobile-phone-connects-icon {
        position: absolute;
        top: 69px;
        right: 17px;
        width: 50px;
        height: 59px;
        background-image: url("../assets/image/components/MobilePhone/connects-icon.png");
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;

        &:active {
          opacity: 0.5;
        }
      }

      .mobile-phone-input-wrap {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 328px;
        height: 38px;
        border-radius: 2px;
        background-color: #2f3845;
      }

      .mobile-phone-item-wrap {
        position: absolute;
        overflow-y: hidden;
        overflow-x: scroll;
        display: flex;
        top: 70px;
        left: 17px;
        width: 330px;
        white-space: nowrap;
        align-items: center;
        padding-bottom: 10px;

        .mobile-phone-item-content {
          width: 70px;
          padding: 0 5px;

          &:hover {
            .mobile-phone-item-active {
              visibility: visible;
            }
          }

          .mobile-phone-item-active {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 70px;
            height: 70px;
            border-radius: 2px;
            background-color: rgba(6, 6, 6, 0.5);
            visibility: hidden;

            .mobile-phone-item-active-phone {
              width: 16px;
              height: 20px;
              margin-top: 8px;
              background-image: url("../assets/image/components/MobilePhone/phone-icon.png");
              background-size: 100%;
              background-repeat: no-repeat;
              cursor: pointer;

              &:active {
                opacity: 0.5;
              }
            }

            .mobile-phone-item-active-del {
              width: 20px;
              height: 20px;
              margin-bottom: 10px;
              background-image: url("../assets/image/components/MobilePhone/del-icon.png");
              background-size: 100%;
              background-repeat: no-repeat;
              cursor: pointer;

              &:active {
                opacity: 0.5;
              }
            }

            .mobile-phone-item-active-setting {
              width: 18px;
              height: 18px;
              background-image: url("../assets/image/components/MobilePhone/icon-setting.png");
              background-size: 100%;
              background-repeat: no-repeat;
              cursor: pointer;
              margin-bottom: 2px;

              &:active {
                opacity: 0.5;
              }
            }
          }

          .mobile-phone-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-size: 16px;

            &:active {
              opacity: 0.5;
            }

            .mobile-phone-item-icon {
              width: 30px;
              height: 38px;
              margin-top: 6px;
              background-image: url("../assets/image/components/MobilePhone/item-icon.png");
              background-size: 100%;
              background-repeat: no-repeat;
            }

            .mobile-phone-item-text {
              width: 70px;
              color: #ffffff;
              font-size: 14px;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .nodata-text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70px;
        }
      }
    }
  }
}

.drawer-wrap {
  .search-input-wrap {
    .search-input {
      background: #4c556a;
      border-radius: 4px;
      margin-bottom: 5px;

      .el-input__icon {
        line-height: 44px;
      }
    }
  }

  .mobile-phone-user-list {
    margin-top: 20px;
    overflow: hidden;

    .mobile-phone-user-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      .mobile-phone-user-info {
        display: flex;
        align-items: center;
        width: 80%;

        .mobile-phone-user-avatar {
          width: 38px;
          height: 48px;
          margin-right: 18px;
          background-image: url("../assets/image/components/MobilePhone/item-icon.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }

        .mobile-phone-user-data {
          display: flex;
          flex-direction: column;
        }

        .mobile-phone-user-name-wrap {
          color: rgba(255, 255, 255, 0.7);
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 14px;

          .mobile-phone-user-name {
            margin-right: 10px;
          }
        }

        .mobile-phone-user-number-wrap {
          color: #ffffff;
          display: flex;
          align-items: center;

          .mobile-phone-user-number {
            margin-right: 6px;
          }

          .mobile-phone-user-number-icon {
            cursor: pointer;
          }
        }
      }

      .mobile-phone-user-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 20%;
        height: 48px;

        .mobile-phone-user-add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 30px;
          background: #2c57d0;
          border-radius: 4px;
          cursor: pointer;

          &:active {
            opacity: 0.5;
          }

          .el-icon {
            font-size: 22px;
          }
        }

        .mobile-phone-user-add-disable {
          background-color: #5b647e;
          cursor: no-drop;

          &:active {
            opacity: 1;
          }
        }

        .mobile-phone-user-call {
          width: 17px;
          height: 21px;
          background-image: url("../assets/image/components/MobilePhone/phone-icon.png");
          background-size: 100%;
          background-repeat: no-repeat;
          cursor: pointer;

          &:active {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .list-tips {
    top: 50%;
    width: 100%;
    color: #ffffff;
    position: absolute;
    text-align: center;
    margin-left: -20px;
    margin-top: -30px;
  }
}

.el-dialog__body .el-form .el-form-item {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-bottom: 0;
}

.dialog-title {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-icon {
    color: #ffffff;
    font-size: 25px;
    margin-right: 5px;
  }

  .dialog-title-name {
    color: #ffffff;
    font-size: 18px;
  }
}

.el-radio-group {
  display: flex;
  flex-direction: column;
}
</style>
