<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:25:41
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-18 19:07:14
 * @FilePath: /zhy_dataview_fe/src/components/BoardVideos.vue
 * @Description: 红外区
-->
<template>
  <div class="BoardVideos general_box_area arrow_card">
    <b class="b bottom_left"></b><b class="b bottom_right"></b>
    <div class="videos-title general_box_header">
      <b class="b top_left"></b><b class="b top_right"></b>
      <div class="general_box_left" @click="goPage">
        <template v-for="(i, index) in $store.state.monitordata.machineList">
          <template v-if="index == 1">
            {{ i.machineTypeName }}
          </template>
        </template>
      </div>
    </div>
    <div class="BoardVideos-content">
      <template v-for="(i, index) in $store.state.monitordata.machineList" :key="index">
        <template v-if="index == 1">
          <div class="board_videos_item" v-for="(a, s) in i.configList" :key="s">
            <div class="board_videos_cover">
              <VideoPlayer :source="a.flvUrl" />
            </div>
            <div class="board_videos_text">{{ a.name }}</div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./InsidePages/VideoPlayer";
export default {
  name: "BoardVideos",
  data() {
    return {};
  },
  computed: {},
  components: {
    VideoPlayer,
  },
  props: {},
  methods: {
    goPage() {
      this.$router.push({
        path: "/monitoring/screen",
        query: { hideNavigation: true },
      });
    },
  },
  created() { },
  mounted() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.BoardVideos {
  position: absolute;
  z-index: 99;
  top: 500px;
  right: 20px;
  width: 420px;
  height: 210px;

  .BoardVideos-content {
    padding: 10px;
    display: flex;

    .board_videos_item {
      width: 190px;
      height: 120px;

      .board_videos_cover {
        width: 190px;
        height: 120px;
        overflow: hidden;

        img {
          width: 100%;
          min-height: 100%;
        }
      }

      &:first-child {
        margin-right: 10px;
      }

      .board_videos_text {
        display: block;
        text-align: center;
        color: #fff;
        margin-top: 15px;
      }
    }
  }
}
</style>
