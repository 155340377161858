<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:24:33
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-08 11:47:44
 * @FilePath: /zhy_dataview_fe/src/components/Meteorology.vue
 * @Description: 水文气象
-->
<template>
  <div class="meteorology general_box_area arrow_card">
    <b class="b bottom_left"></b><b class="b bottom_right"></b>
    <div class="meteorology-title general_box_header">
      <b class="b top_left"></b><b class="b top_right"></b>
      <div class="general_box_left" @click="Hydrometeorclick($event)">
        水文气象
      </div>
      <div class="general_box_right">
        <el-button type="text" style="position: relative; z-index: 999" class="onmore" v-if="!meteorologyChart"
          @click="openMeteorologyChart">{{ "展开" }}</el-button>
        <el-button type="text" style="position: relative; z-index: 999" class="onmore" v-if="meteorologyChart"
          @click="closeMeteorologyChart">{{ "收缩" }}</el-button>
      </div>
    </div>
    <div class="meteorology-content general_box_content">
      <div class="meteorology_data_panel">
        <div class="md_panel_left">
          <div class="md_panel_temperature">
            <div class="icon_temperature"></div>
            <div class="temperature_num">
              <template v-if="daydata">
                <span class="temperature_big_num">{{ meteorodata.temp || 0 }}°</span><span class="temperature_desc">{{
                thisDayData.minTemp || 0 }}°/{{
                  thisDayData.maxTemp || 0
                  }}°</span>
              </template>
              <template v-else> </template>
            </div>
          </div>
          <div class="md_panel_date">
            <template v-if="daydata">
              {{ thisDayData.date || "" }}
            </template>
          </div>
        </div>
        <div class="md_panel_right">
          <div class="md_panel_num_item">
            <div class="md_panel_num_text">湿度：</div>
            <div class="md_panel_num_conetnt arrow_card emergency">
              <b class="b bottom_left"></b><b class="b bottom_right"></b>
              <b class="b top_left"></b><b class="b top_right"></b>
              {{ meteorodata.humidity }}%
            </div>
          </div>
          <div class="md_panel_num_item">
            <div class="md_panel_num_text">风向：</div>
            <div class="md_panel_num_conetnt arrow_card">
              <b class="b bottom_left"></b><b class="b bottom_right"></b>
              <b class="b top_left"></b><b class="b top_right"></b>
              {{ meteorodata.windDirection }}°
            </div>
          </div>
          <div class="md_panel_num_item">
            <div class="md_panel_num_text">气压：</div>
            <div class="md_panel_num_conetnt arrow_card">
              <b class="b bottom_left"></b><b class="b bottom_right"></b>
              <b class="b top_left"></b><b class="b top_right"></b>
              {{ meteorodata.airPressure }}hPa
            </div>
          </div>
          <div class="md_panel_num_item">
            <div class="md_panel_num_text">风速：</div>
            <div class="md_panel_num_conetnt arrow_card">
              <b class="b bottom_left"></b><b class="b bottom_right"></b>
              <b class="b top_left"></b><b class="b top_right"></b>
              {{ meteorodata.windSpeed }}m/s
            </div>
          </div>
        </div>
      </div>
      <div class="meteorology_data_chart" v-if="meteorologyChart">
        <div class="meteorologytext" v-if="meteorodata.tempList.length" style="
            padding: 5px;
            display: flex;
            width: 100%;
            height: 220px;
            flex-wrap: wrap;
          ">
          <div style="width: 100%; display: flex; margin-bottom: 15px; color: #fff">
            <div style="width: 60px" v-for="i in meteorodata.tempList">
              <p style="text-align: center">{{ i.week }}</p>
              <p style="text-align: center; margin-top: 5px">
                {{ i.date }}
              </p>
            </div>
          </div>
          <div style="width: 100%; display: flex; color: #fff">
            <div style="width: 60px" v-for="i in meteorodata.tempList">
              <p style="text-align: center; font-size: 12px">
                {{ i.maxTemp }}°
              </p>
            </div>
          </div>
          <div style="width: 100%; margin-top: -10px">
            <apexchart type="line" height="50" :options="chartOption(0)" :series="series" ref="demoChart" />
          </div>
          <div style="width: 100%; display: flex; color: #fff">
            <div style="width: 60px" v-for="i in meteorodata.tempList">
              <p style="text-align: center; font-size: 12px">
                {{ i.minTemp }}°
              </p>
            </div>
          </div>
          <div style="width: 100%; margin-top: -10px">
            <apexchart type="line" height="50" :options="chartOption(1)" :series="series2" ref="demoChart2" />
          </div>
          <div style="width: 100%; display: flex; color: #fff">
            <div style="width: 60px" v-for="i in meteorodata.tempList">
              <p style="text-align: center">
                {{ i.weather ? i.weather + "°" : "-" }}
              </p>
            </div>
          </div>
          <div style="width: 100%; margin-top: -10px">
            <apexchart type="line" height="50" :options="chartOption(2)" :series="series3" ref="demoChart3" />
          </div>
        </div>
        <div class="meteorologytext_nodata" v-else>当前暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { meteorohomehydr } from "@/api/hydrometeor/index.js";
export default {
  name: "Meteorology",
  data() {
    return {
      meteorologyChart: false,
      meteorodata: {},
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
          offsetX: -20,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          show: false,
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          labels: {
            hideOverlappingLabels: true,
            trim: true,
          },
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      series: [
        {
          name: "最高气温",
          data: [],
        },
      ],
      series2: [
        {
          name: "最低气温",
          data: [],
        },
      ],
      series3: [
        {
          name: "当前气温",
          data: [],
        },
      ],
      maxTempArr: [],
      minTempArr: [],
      weather: [],
      week: [],
      daydata: false,
      thisDayData: {},
    };
  },
  computed: {
    chartOption: function () {
      const chartOptions = this.chartOptions;
      return function (index) {
        const colors = {
          0: ["#1ca7fc"],
          1: ["#2cfee1"],
          2: ["#ea9330"],
        };
        return { ...chartOptions, colors: colors[index] };
      };
    },
  },
  components: {},
  props: {},
  methods: {
    Hydrometeorclick(val) {
      this.$router.push("/hydrometeor");
    },
    openMeteorologyChart() {
      this.$emit("meteorologyChart", true);
      this.meteorologyChart = true;
      this.getMeteorohomehydr();
    },
    closeMeteorologyChart() {
      this.$emit("meteorologyChart", false);
      this.meteorologyChart = false;
    },
    getMeteorohomehydr() {
      let maxTempArr = [],
        minTempArr = [],
        weather = [],
        week = [];
      meteorohomehydr().then((res) => {
        const { code } = res;
        if (code === "B200") {
          this.meteorodata = res.data;
          if (this.meteorodata.tempList.length == 0) return
          if (this.meteorodata.tempList.length >= 1) {
            this.thisDayData =
              this.meteorodata.tempList[this.meteorodata.tempList.length - 1]; //当天的数据
            this.daydata = true;
          }
          res.data.tempList.forEach((element) => {
            maxTempArr.push(element.maxTemp);
            minTempArr.push(element.minTemp);
            weather.push(element.weather);
            week.push(element.week);
            this.maxTempArr = maxTempArr;
            this.minTempArr = minTempArr;
            this.weather = weather;
            this.week = week;
          });
          this.series = [
            {
              name: "最高气温",
              data: maxTempArr,
            },
          ];
          this.series2 = [
            {
              name: "最低气温",
              data: minTempArr,
            },
          ];
          this.series3 = [
            {
              name: "当前气温",
              data: weather,
            },
          ];
          if (this.meteorologyChart) {
            this.$refs.demoChart.updateOptions({
              xaxis: {
                categories: week,
              },
            });
          }
        }
      });
    },
  },
  created() { },
  beforeUnmount() { },
  mounted() {
    this.getMeteorohomehydr();
  },
};
</script>

<style lang="scss">
.meteorology {
  position: absolute;
  z-index: 99;
  top: 98px;
  right: 20px;
  width: 420px;

  .meteorologytext_nodata {
    width: 100%;
    height: 220px;
    color: #fff;
    border-top: 1px solid rgba(42, 46, 58, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.meteorology_data_panel {
  padding: 10px;
  display: flex;
  color: #fff;
  font-size: 12px;

  .md_panel_left {
    width: 160px;
    margin-right: 15px;

    .md_panel_temperature {
      display: flex;
      padding: 5px 0;
      margin-bottom: 10px;
    }

    .icon_temperature {
      width: 40px;
      height: 56px;
      background: url(../assets/icons/icon_temperature.png) no-repeat center center;
      background-size: 100%;
    }

    .temperature_num {
      width: 60px;
      margin-top: 20px;

      .temperature_big_num {
        text-align: center;
        display: block;
        font-size: 32px;
      }

      .temperature_desc {
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .md_panel_date {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .md_panel_right {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    overflow: hidden;

    .md_panel_num_item {
      width: 50%;
      display: flex;
      line-height: 42px;
      margin-bottom: 10px;
    }

    .md_panel_num_conetnt {
      background: rgba(30, 95, 223, 0.3);
      color: rgba(47, 211, 202, 1);
      text-align: center;
      width: 80px;
      height: 42px;
    }

    .emergency {
      color: rgba(251, 54, 70, 1);
      background: rgba(251, 54, 70, 0.3);

      .bottom_left {
        border-color: transparent transparent rgba(251, 54, 70, 1) rgba(251, 54, 70, 1);
      }

      .bottom_right {
        border-color: transparent rgba(251, 54, 70, 1) rgba(251, 54, 70, 1) transparent;
      }

      .top_right {
        border-color: rgba(251, 54, 70, 1) rgba(251, 54, 70, 1) transparent transparent;
      }

      .top_left {
        border-color: rgba(251, 54, 70, 1) transparent transparent rgba(251, 54, 70, 1);
      }
    }
  }
}

.meteorology_data_chart {
  .apexcharts-menu-icon {
    display: none;
  }

  .apexcharts-xaxis {
    display: none;
  }

  .apexcharts-xaxis-tick {
    display: none;
  }

  .apexcharts-text {
    display: none;
  }

  .apexcharts-canvas {
    margin-top: -10px;
  }
}
</style>
