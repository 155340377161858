<!--
import { request } from 'http';
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-02-25 15:38:33
 * @FilePath: /zhy_dataview_fe/src/components/BottomMenu.vue
 * @Description: 底部菜单
-->
<template>
  <div class="bottom-menu">
    <div
      class="bottom-menu-item"
      v-for="(item, index) in itemArr"
      :key="index"
      @click="onItem(item, index)"
    >
      <div class="bottom-menu-item-active"></div>
      <div class="bottom-menu-item-icon">
        <img class="image" :src="item.icon" :alt="item.name" />
      </div>
      <div class="bottom-menu-item-name">{{ item.name }}</div>
    </div>
    <a :href="bgHref()" target="_blank" v-show="false">运营管理平台</a>
  </div>
</template>

<script>
import { config } from "@/config/index";

export default {
  name: "BottomMenu",
  data() {
    return {
      itemArr: [
        {
          id: 1,
          name: "可视调度",
          router: "meeting",
          icon: require("../assets/image/components/BottomMenu/icon-1.png"),
        },
        {
          id: 2,
          name: "系统设置",
          router: "",
          icon: require("../assets/image/components/BottomMenu/icon-2.png"),
        },
      ],
    };
  },
  computed: {},
  components: {},
  props: {},
  methods: {
    onItem(item, index) {
      const { id } = item;
      if (id == 1) {
        this.$router.push({
          name: item.router,
        });
      } else {
        const adminUrl = `${
          config.adminUrl
        }/#/login?token=${localStorage.getItem("ACCESS_TOKEN")}`;
        console.log(adminUrl);
        window.open(adminUrl, "_blank");
      }
    },
    // 运营管理平台，跳转链接
    bgHref() {
      return `//zhy-admin.xhangjia.com/login?token=${localStorage.getItem(
        "ACCESS_TOKEN"
      )}`;
    },
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style lang="scss">
.bottom-menu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  bottom: 30px;
  max-width: 802px;
  height: 150px;
  z-index: 1;
  transform: translateX(-50%);
  .bottom-menu-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 130px;
    height: 100%;
    cursor: pointer;
    &:hover .bottom-menu-item-active {
      visibility: visible;
    }
    .bottom-menu-item-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 66px;
    }
    .bottom-menu-item-name {
      position: relative;
      margin-top: 15px;
      color: #ffffff;
      font-size: 18px;
    }
    .bottom-menu-item-active {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: url("../assets/image/components/BottomMenu/active.png");
      background-size: 100%;
      background-repeat: no-repeat;
      visibility: hidden;
    }
  }
}
</style>
