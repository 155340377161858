<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:16:54
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-04-14 15:06:40
 * @FilePath: /zhy_dataview_fe/src/components/EventLog.vue
 * @Description: 事件日志
-->
<template>
  <div class="event-log general_box_area arrow_card">
    <b class="b bottom_left"></b><b class="b bottom_right"></b>
    <div class="event-log-title general_box_header">
      <b class="b top_left"></b><b class="b top_right"></b>
      <div class="general_box_left" @click="Eventclick($event)">事件日志</div>
      <div class="general_box_right">
        <el-button type="text" v-show="!drawerShow" @click="openEventLogMore">{{
          "展开"
        }}</el-button>
        <el-button type="text" v-show="drawerShow" @click="drawerShow = false">{{ "关闭" }}</el-button>
      </div>
    </div>
    <div class="event-log-content">
      <ul class="elc-type">
        <li class="red">
          <div class="icon"></div>
          <div class="num">
            告警事件({{
              eventTypeNum.alarmNum > 999 ? "999+" : eventTypeNum.alarmNum
            }})
          </div>
        </li>
        <li class="yellow">
          <div class="icon"></div>
          <div class="num">
            广播事件({{
              eventTypeNum.broadcastNum > 999
                ? "999+"
                : eventTypeNum.broadcastNum
            }})
          </div>
        </li>
        <li class="green">
          <div class="icon"></div>
          <div class="num">
            通知事件({{
              eventTypeNum.messageNum > 999 ? "999+" : eventTypeNum.messageNum
            }})
          </div>
        </li>
      </ul>
      <div class="elc-list-wrap">
        <ul class="elc-list" ref="elcListRef">
          <li v-for="item in eventMessageList" :key="item.id" :class="[eventTypeMap[item.eventType]]">
            <div class="title ellipsis-text" :title="item.content">
              {{ item.content }}
            </div>
            <div class="time">
              {{ formatMsgTime(item.eventTime, true) }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="event_log_more" v-if="drawerShow">
      <div class="general_header_area">
        <div class="general_header_left">事件日志</div>
      </div>
      <div class="event_log_more_content">
        <div class="event_log_header">
          <div class="ev_title">事件标题</div>
          <div class="ev_time">时间</div>
          <div class="ev_content">内容</div>
        </div>
        <ul>
          <li style="cursor: pointer" v-for="(item, index) in moreEventLogList" :class="{
              exigency_level: item.eventType == 'ALARM',
              wran_level: item.eventType == 'BROADCAST',
              info_level: item.eventType == 'MESSAGE',
            }" :key="index">
            <div class="ev_title" :title="item.title">{{ item.title }}</div>
            <div class="ev_time" :title="
                item.eventTime
                  ? moment(item.eventTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'
              ">
              {{
                item.eventTime ? moment(item.eventTime).format("HH:mm") : "-"
              }}
            </div>
            <div class="ev_content" :title="item.content">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import EventLogMore from "./EventLogMore";
import moment from "moment";

export default {
  name: "EventLog",
  data() {
    return {
      updateTimer: null,
      drawerShow: false,
      eventTypeNum: {
        alarmNum: 0,
        broadcastNum: 0,
        messageNum: 0,
      },
      eventTypeMap: {
        ALARM: "red",
        BROADCAST: "yellow",
        LINKAGE: "green",
      },
      moreEventLogList: [],
      eventMessageList: [],
      formatMsgTime: utils.formatMsgTime,
    };
  },
  methods: {
    moment,
    getLogTime() {
      this.$api.getLogTime().then((res) => {
        const { code, data } = res;
        if (code == "B200") {
          const time = data ? data : 30;
          this.updateTimer = setTimeout(() => {
            this.getSnapshotApi();
            this.getLogTime();
          }, time * 1000);
        }
      });
    },
    clearTimeout() {
      clearTimeout(this.updateTimer);
      this.updateTimer = null;
      this.$config.consoleLog && console.log("已清除事件日志轮询定时器");
    },
    // 获取首页事件日志列表接口
    getSnapshotApi() {
      this.$api.getSnapshot().then((res) => {
        const { code, msg } = res;
        if (code === "B200") {
          const data = res.data;
          const { alarmNum, broadcastNum, messageNum, eventMessageList } = data;
          this.eventTypeNum = {
            alarmNum,
            broadcastNum,
            messageNum,
          };
          this.eventMessageList = eventMessageList;
          // 每获取一次列表就回到元素顶部，这样才能看到最新的
          if (this.$refs.elcListRef) {
            this.$refs.elcListRef.scrollTop = 0;
          }
        }
      });
    },
    // 查看更多
    moreEvent() {
      this.drawerShow = true;
    },
    Eventclick(val) {
      this.$router.push("/eventlog");
    },
    // 查看更多事件日志
    openEventLogMore() {
      this.drawerShow = true;
      const _self = this;
      this.$api
        .getEventMessageMore({
          page: 1,
          pageSize: 10,
        })
        .then((res) => {
          _self.moreEventLogList = res.data;
        });
    },
  },
  mounted() {
    this.getSnapshotApi();
    this.getLogTime();
  },
  unmounted() {
    this.clearTimeout();
  },
  components: {
    EventLogMore,
  },
};
</script>

<style lang="scss">
.event-log {
  position: absolute;
  z-index: 99;
  top: 102px;
  left: 20px;
  width: 420px;
  height: 285px;
  .event-log-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  .event-log-content {
    position: absolute;
    top: 40px;
    width: 100%;
    height: 244px;
    color: #fff;
    .elc-type {
      display: flex;
      padding: 5px 0 10px;
      position: relative;
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        background-image: linear-gradient(to right, #33374a, #39556d);
      }
      li {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        .icon {
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          margin-bottom: 5px;
        }
        .num {
          font-size: 16px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
      .red {
        color: #ab1006;
        .icon {
          background-image: url("../assets/image/components/EventLog/red-type.png");
        }
      }
      .yellow {
        color: #e49729;
        .icon {
          background-image: url("../assets/image/components/EventLog/yellow-type.png");
        }
      }
      .green {
        color: #33d3c9;
        .icon {
          background-image: url("../assets/image/components/EventLog/green-type.png");
        }
      }
    }
    .elc-list-wrap {
      padding-left: 10px;
      height: 168px;
      overflow-y: hidden;
      position: relative;
      &::after {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 15px;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
      }
      .elc-list {
        position: relative;
        height: 100%;
        overflow-y: auto;
        padding: 0px 15px 0px 10px;
        li {
          height: 37px;
          display: flex;
          align-items: center;
          position: relative;
          border-left: 1px solid #34384c;
          padding-left: 10px;
          &:first-child {
            padding-top: 5px;
          }
          &:last-child {
            padding-bottom: 5px;
          }
          &::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            opacity: 0.9;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -3px;
          }
          &.red {
            .title {
              color: #c12014;
            }
            &::before {
              background-color: #c12014;
            }
          }
          &.yellow {
            .title {
              color: #f5b00a;
            }
            &::before {
              background-color: #f5b00a;
            }
          }
          &.green {
            .title {
              color: #00eced;
            }
            &::before {
              background-color: #00eced;
            }
          }
          .title {
            flex: 1;
          }
          .time {
            margin-left: 15px;
            text-align: right;
          }
        }
      }
    }
  }

  .event_log_more {
    position: absolute;
    background: #181825;
    left: 420px;
    top: 0px;
    z-index: 1;
    width: 500px;
    height: 400px;
    .event_log_more_content {
      padding: 10px;
    }
    .event_log_header {
      background: rgba(51, 56, 71, 0.7);
      display: flex;
      line-height: 38px;
      color: rgba(255, 255, 255, 0.6);
      border-radius: 4px;
      padding: 0 10px;
    }
    ul {
      overflow-y: auto;
      height: 300px;
    }
    li {
      display: flex;
      font-size: 12px;
      line-height: 40px;
      padding: 0 10px;
    }
    .ev_title {
      width: 120px;
    }
    .ev_time {
      width: 70px;
    }
    .ev_content {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .info_level {
      color: #29dbc9;
    }
    .wran_level {
      color: #efb63b;
    }
    .exigency_level {
      color: #d42d21;
    }
  }

  //公用小标题
  .general_header_area {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8);
    background: #2a2e3a;
    .general_header_left {
      position: relative;
      text-indent: 20px;
      line-height: 30px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        left: 0;
        top: 9px;
        background: #4d79f5;
      }
    }
    .general_header_right {
      display: flex;
      .general_header_item {
        width: 60px;
        height: 30px;
        background: rgba(70, 77, 98, 0.7);
        border-radius: 4px;
        margin-left: 10px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        cursor: pointer;
      }
      .active {
        background: #6a7693;
      }
    }
  }
}
</style>
