<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:25:41
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-14 15:02:19
 * @FilePath: /zhy_dataview_fe/src/components/MonitoringVideos.vue
 * @Description: 防登临
-->
<template>
  <div class="MonitoringVideos general_box_area arrow_card">
    <b class="b bottom_left"></b><b class="b bottom_right"></b>
    <div class="videos-title general_box_header">
      <b class="b top_left"></b><b class="b top_right"></b>
      <div class="general_box_left" @click="goPage">
        <template v-for="(i, index) in this.$store.state.monitordata.machineList">
          <template v-if="index == 2">
            {{ i.machineTypeName }}
          </template>
        </template>
      </div>
    </div>
    <div class="MonitoringVideos-content">
      <template v-for="(i, index) in $store.state.monitordata.machineList">
        <template v-if="index == 2">
          <template v-for="(a, s) in i.configList">
            <template v-if="s < 2">
              <div class="monitoring_videos_item">
                <div class="monitoring_videos_cover">
                  <VideoPlayer :source="a.flvUrl" />
                </div>
                <div class="monitoring_videos_text">
                  {{ a.name }}
                </div>
              </div>
            </template>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./InsidePages/VideoPlayer";

export default {
  name: "MonitoringVideos",
  data() {
    return {};
  },
  computed: {},
  components: { VideoPlayer },
  props: {},
  methods: {
    goPage() {
      this.$router.push({
        path: "/monitoring/screen",
        query: { hideNavigation: true },
      });
    },
  },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.MonitoringVideos {
  position: absolute;
  z-index: 99;
  top: 280px;
  right: 20px;
  width: 420px;
  height: 200px;

  .MonitoringVideos-content {
    padding: 10px;
    display: flex;

    .monitoring_videos_item {
      width: 190px;
      height: 106px;

      .monitoring_videos_cover {
        width: 190px;
        height: 106px;
        overflow: hidden;

        img {
          width: 100%;
          min-height: 100%;
        }
      }

      &:first-child {
        margin-right: 10px;
      }

      .monitoring_videos_text {
        display: block;
        text-align: center;
        color: #fff;
        margin-top: 15px;
      }
    }
  }
}
</style>
