<!--
 * @Author: Zion0707
 * @Date: 2021-10-25 18:16:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-30 11:46:31
 * @FilePath: /zhy_dataview_fe/src/components/EventLog.vue
 * @Description: 事件日志
-->
<template>
  <div class="event-log_more">
    <div class="datetime">
      <el-date-picker @change="selectDatePickerEvent" style="width: 100%" v-model="datetime" type="date" placeholder="选择日期"></el-date-picker>
    </div>
    <div class="el_more__tabs">
      <ul class="tabs-btn">
        <li v-for="(item, index) in Object.keys(numMap)" :key="item" :class="[currentTab === index && 'current']" @click="currentTab = index">
          {{ tabsNameMap[item] }}({{ numMap[item] > 999 ? "999+" : numMap[item] }})
        </li>
      </ul>
      <div class="tabs-con">
        <div v-for="(item, index) in Object.keys(listMap)" :key="item" v-show="currentTab === index" class="tabs-con__item">
          <ul class="tabs-con__item-thead">
            <li>事件日志</li>
            <li>时间</li>
            <li>地点</li>
            <li>内容</li>
          </ul>
          <div class="tabs-con__item-tbody-wrap">
            <div class="tabs-con__item-tbody">
              <ul v-for="item in listMap[item]" :key="item.id" :class="[eventTypeMap[item.eventType]]" @click="checkDetailEvent(item.id)">
                <li class="ellipsis-text">{{ item.title }}</li>
                <li>{{ formatMsgTime(item.eventTime) }}</li>
                <li class="ellipsis-text">
                  {{ item.eventLocation }}
                </li>
                <li class="ellipsis-text">
                  {{ item.content }}
                </li>
              </ul>
              <div v-if="listMap[item].length === 0" class="no-data">--暂无数据--</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer v-model="drawerShow" title="事件日志" :direction="'ltr'">
      <EventLogDetail v-if="drawerShow" :id="detailId" />
    </el-drawer>
  </div>
</template>

<script>
import utils from "@/utils";
import dayjs from "dayjs";
import EventLogDetail from "./EventLogDetail";

export default {
  data() {
    return {
      drawerShow: false,
      detailId: "",

      eventTypeMap: {
        ALARM: "red",
        BROADCAST: "yellow",
        LINKAGE: "green",
      },
      datetime: "",
      currentTab: 0,
      numMap: {
        collectNum: 0,
        alarmNum: 0,
        broadcastNum: 0,
        linkageNum: 0,
      },
      tabsNameMap: {
        collectNum: "全部事件",
        alarmNum: "告警事件",
        broadcastNum: "广播事件",
        linkageNum: "联动事件",
      },
      listMap: {
        collectList: [], //汇总事件
        alarmList: [], //告警事件
        broadcastList: [], //广播事件
        linkageList: [], //通知事件
      },
      formatMsgTime: utils.formatMsgTime,

      // 分页统一一页数
      pageSize: 100,
    };
  },
  methods: {
    // 查看详情
    checkDetailEvent(id) {
      this.detailId = id;
      this.drawerShow = true;
    },

    // 时间格式转换
    dateScope() {
      let eventTimeBegin = "";
      let eventTimeEnd = "";
      if (this.datetime) {
        const date = dayjs(this.datetime).format("YYYY-MM-DD");
        eventTimeBegin = `${date}`;
        eventTimeEnd = `${date}`;
      }
      return { eventTimeBegin, eventTimeEnd };
    },

    // 汇总列表
    getCollectList(page) {
      const { eventTimeBegin, eventTimeEnd } = this.dateScope();
      this.$api
        .getEventMessageList({
          page,
          pageSize: this.pageSize,
          eventType: "",
          eventTimeBegin,
          eventTimeEnd,
        })
        .then((res) => {
          const { total, data } = res;
          this.numMap.collectNum = total;
          this.listMap.collectList = data;
        });
    },
    //告警事件列表获取
    getAlarmList(page) {
      const { eventTimeBegin, eventTimeEnd } = this.dateScope();
      this.$api
        .getEventMessageList({
          page,
          pageSize: this.pageSize,
          eventType: "ALARM",
          eventTimeBegin,
          eventTimeEnd,
        })
        .then((res) => {
          const { total, data } = res;
          this.numMap.alarmNum = total;
          this.listMap.alarmList = data;
        });
    },
    //广播事件列表获取
    getBroadcastList(page) {
      const { eventTimeBegin, eventTimeEnd } = this.dateScope();
      this.$api
        .getEventMessageList({
          page,
          pageSize: this.pageSize,
          eventType: "BROADCAST",
          eventTimeBegin,
          eventTimeEnd,
        })
        .then((res) => {
          const { total, data } = res;
          this.numMap.broadcastNum = total;
          this.listMap.broadcastList = data;
        });
    },
    //通知事件列表获取
    getLinkageList(page) {
      const { eventTimeBegin, eventTimeEnd } = this.dateScope();
      this.$api
        .getEventMessageList({
          page,
          pageSize: this.pageSize,
          eventType: "LINKAGE",
          eventTimeBegin,
          eventTimeEnd,
        })
        .then((res) => {
          const { total, data } = res;
          this.numMap.linkageNum = total;
          this.listMap.linkageList = data;
        });
    },
    // 事件日志列表
    getEventMessageListApi() {
      this.getCollectList(1);
      this.getAlarmList(1);
      this.getBroadcastList(1);
      this.getLinkageList(1);
    },

    // 日期选择事件
    selectDatePickerEvent() {
      this.getEventMessageListApi();
    },
  },
  mounted() {
    this.getEventMessageListApi();
  },
  components: {
    EventLogDetail,
  },
  unmounted() {},
};
</script>

<style lang="scss">
.event-log_more {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  overflow: hidden;
  .datetime {
    background-color: #4a4f60;
    width: 100%;
    margin: 0 auto 20px;
  }
  .el_more__tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .tabs-btn {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      li {
        user-select: none;
        cursor: pointer;
        width: 24%;
        height: 40px;
        background-color: #2a2e3a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:nth-child(1) {
          color: #a3e7ff;
        }
        &:nth-child(2) {
          color: #b60211;
        }
        &:nth-child(3) {
          color: #e49729;
        }
        &:nth-child(4) {
          color: #38d3da;
        }
        &.current {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: #3566f1;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
    .tabs-con {
      flex: 1;
      .tabs-con__item {
        display: flex;
        flex-direction: column;
        height: 100%;
        .tabs-con__item-thead {
          display: flex;
          li {
            font-size: 14px;
            align-items: center;
            height: 40px;
            line-height: 40px;
          }
        }
        .tabs-con__item-tbody-wrap {
          flex: 1;
          position: relative;
        }
        .tabs-con__item-tbody {
          overflow-y: auto;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          ul {
            display: flex;
            &.red {
              color: #ab1006;
            }
            &.yellow {
              color: #e49729;
            }
            &.green {
              color: #33d3c9;
            }
            li {
              font-size: 16px;
              height: 40px;
              line-height: 40px;
              cursor: pointer;
            }
          }
        }
        .tabs-con__item-thead,
        .tabs-con__item-tbody ul {
          li {
            text-align: center;
            padding: 0 5px;
            &:nth-child(1) {
              width: 20%;
            }
            &:nth-child(2) {
              width: 30%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 30%;
            }
          }
        }
      }
    }
  }
}
.no-data {
  padding: 20px;
  text-align: center;
  font-size: 12px;
  color: #999999;
}
</style>
